import React, { Component } from "react";
import { connect } from "react-redux";
import { __postDataLogout } from "../../modules/Logout";
import { Card } from "reactstrap";
import { CardBody } from "reactstrap";

export class Logout extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    const { sessionId } = this.props;
    if (sessionId) {
      this.props.__postDataLogout({ session_id: sessionId });
    }
  }

  clearLocalAndReload = () => {
    window.location.reload();
    localStorage.clear();
  };
  render() {
    const { modulLogout } = this.props;
    const { loadingSend, code } = modulLogout;

    return (
      <div className="wrapper-login">
        <div className="login-container">
          <Card className="py-2 px-2">
            {!loadingSend && code === 200 ? (
              this.clearLocalAndReload()
            ) : (
              <CardBody>
                <h4 className=" mb-4 text-center">Loading...</h4>
              </CardBody>
            )}
          </Card>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ modulLogout }) => ({
  modulLogout,
});

const mapDispatchToProps = (dispatch) => ({
  __postDataLogout: (params) => [dispatch(__postDataLogout(params))],
});

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
