import React, { Component } from 'react';
// import AppleLogin from "react-apple-login";
import { connect } from 'react-redux';
// import { SSO_GOOGLE } from '../../components';
import Facebook from '../../components/sso/Facebook';
import jwtDecode from 'jwt-decode';
import { __postDataSso } from '../../modules/Auth';
// import { __postSignUpNewAccount } from "../../modules/Signup";
import pkgJson from '../../../../package.json';
// import GoogleOauth from '../sso/GoogleOauth';
import GoogleOauthApp from '../sso/GoogleOauth2';
import { GoogleOAuthProvider } from '@react-oauth/google';
// import { SSO_GOOGLE } from '..'
const googleClientoauth2 = process.env.REACT_APP_GOOGLE_CLIENT_OAUTH_2;
export class LoginSso extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        user: '',
        password: '',
        appname: '',
        recaptcha: '',
      },
      dataSso: {
        code: null,
        token: null,
      },
      isAuthenticate: false,
      step: 1,
    };
  }
  responseApple = (res) => {
    const { data } = this.state;
    const { appname } = data;

    if (res.error) {
      // console.log('error login apple', res)
    } else if (res.authorization) {
      const { id_token } = res.authorization;
      // const { user } = res;
      const data = {
        token: res.authorization.id_token,
        code: res.authorization.code,
        type_of_account: 'apple',
      };

      var decodedHeader = jwtDecode(id_token);
      const { email } = decodedHeader;

      let dataSent = {
        authenticate: data,
        appname: appname,
        user: {
          emails: [{ email: email, name: '' }],
        },
      };
      const domainEmail = email.split('@').pop();
      if (domainEmail !== 'privaterelay.appleid.com') {
        return this.props.__postDataSso(dataSent);
      }

      return this.props.__openModal({
        modal: 'CONFIRM_LOGIN',
        open: true,
        data: dataSent,
      });
    }
  };

  responseGoole = (response) => {
    const { tokenId, profileObj } = response;
    // console.log(response);
    const { data } = this.state;
    const { appname } = data;
    if (tokenId && profileObj) {
      const data = {
        token: tokenId,
        type_of_account: 'google',
      };
      const dataUser = {
        first_name: profileObj.name,
        last_name: profileObj.givenName,
        avatar: profileObj.imageUrl,
        emails: [{ email: profileObj.email, name: profileObj.name }],
      };
      let dataSent = {
        authenticate: data,
        user: dataUser,
        appname: appname,
      };

      // console.log(dataSent)

      return this.props.__postDataSso(dataSent);
    }
  };

  newresponseGoole = (response) => {
    // console.log(response)
    const { code } = response;
    const { data } = this.state;
    const { appname } = data;

    const dataAuth = {
      code: code,
      type_of_account: 'google-oauth2',
      redirect_uri: window.location.origin,
    };

    let dataSent = {
      authenticate: dataAuth,
      appname: appname,
      user: {},
    };

    return this.props.__postDataSso(dataSent);
  };

  render() {
    const { disabled, formLogin, modulSsoFacebook, modulSso, btnSso } = this.props;
    const { loadingPage } = modulSsoFacebook;
    return (
      <>
        {formLogin ? (
          <>
            <div className='text-center d-flex justify-content-center'></div>
            <p className='my-3 login-divider text-center'>or</p>
          </>
        ) : (
          ''
        )}

        <div>
          {btnSso ? (
            <GoogleOAuthProvider clientId={googleClientoauth2}>
              <GoogleOauthApp
                callback={this.newresponseGoole}
                disabled={disabled || loadingPage}
                pleaseWait={modulSso.loadingPage}
              />
            </GoogleOAuthProvider>
          ) : (
            ''
          )}
        </div>
        {formLogin && btnSso ? (
          <div className='mt-3'>
            <Facebook disabled={disabled || modulSso.loadingPage} pleaseWait={loadingPage} />
          </div>
        ) : (
          ''
        )}

        {/* <div className='apple-login-container'>
					<AppleLogin
						clientId='com.giftano.account'
						scope='name email'
						redirectURI={window.location.origin}
						usePopup={true}
						designProp={{
							width: 375,
							height: 45,
							border_radius: 50,
						}}
						disabled={disabled}
						callback={this.responseApple}
					/>
				</div> */}
        <h6 className='text-center mt-4 font-weight-bold text-dark auth-version'>
          V.{pkgJson.version}
        </h6>
      </>
    );
  }
}

const mapStateToProps = ({ modulSignUp, modulSsoFacebook, modulSso }) => ({
  modulSignUp,
  modulSsoFacebook,
  modulSso,
});

const mapDispatchToProps = (dispatch) => ({
  __postDataSso: (params) => [dispatch(__postDataSso(params))],
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginSso);
