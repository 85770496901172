import React, { Component, Suspense } from 'react';
import { connect, Provider } from 'react-redux';
import { BrowserRouter, Route } from 'react-router-dom';
import Authentication from './layouts/authentication';
import Portal from './layouts/portal';
// import { loadReCaptcha } from 'react-recaptcha-v3';
import { HocAuthenticate, Loading, ProviderLoading } from './components';
import axios from 'axios';
// import { PageVerify } from "./pages";
// import SetupPage from './pages/v2/signup'
import { default as BlankPage } from './layouts/blankPage';
// import pakg from "../../package.json";
import { __getDataStrapi } from './modules/Content/Strapi_act';
// import MatomoProvider from './layouts/MatomoProvider';
// import { GoogleReCaptchaProvider } from 'react-recaptcha-v3';

// const RECHAPTCHA_CLIENT = process.env.REACT_APP_RECHAPTCHA;
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: false,
      mute: true,
    };
    let request_id = '';
    axios.interceptors.request.use((config) => {
      if (request_id) {
        config.headers['gf-request-id'] = request_id;
      }
      return config;
    });
    axios.interceptors.response.use((value) => {
      if (value.headers['gf-request-id']) {
        request_id = value.headers['gf-request-id'];
      }
      return value;
    });
  }

  componentDidMount() {
    // loadReCaptcha(RECHAPTCHA_CLIENT);
  }

  componentWillUnmount() {
    this.setState((state) => ({
      ...state,
      mute: false,
    }));
  }
  render() {
    const { auth, store, loading } = this.props;
    // console.log(loading);
    return (
      <Provider store={store}>
        <ProviderLoading>
          <Loading visible={loading} />
          <BrowserRouter basename='/'>
            <Suspense>
              {/* <Switch> */}
              <Route
                path={'/setup/:key'}
                exact={true}
                name='Logout'
                title='Logout'
                render={(props) => {
                  return <BlankPage {...this.props} {...props} />;
                }}
              />
              <Route
                path={'/reset-password/:key'}
                name='Login Page'
                title='Signup Page'
                render={(props) => <BlankPage {...this.props} {...props} />}
              />
              <Route
                path={'/set/:key'}
                exact={true}
                name='Logout'
                title='Logout'
                render={(props) => {
                  return <BlankPage {...this.props} {...props} />;
                }}
              />
              {!loading ? (
                auth && !loading ? (
                  <>
                    <Route
                      name='Account'
                      exact={true}
                      render={(props) => <Portal {...this.props} {...props} />}
                    />
                  </>
                ) : (
                  <>
                    <Route
                      name='Account'
                      exact={true}
                      render={(props) => {
                        return <Authentication {...props} {...this.props} />;
                      }}
                    />
                  </>
                )
              ) : (
                ''
              )}
              {/* </Switch> */}
            </Suspense>
          </BrowserRouter>
        </ProviderLoading>
      </Provider>
    );
  }
}
const mapStateToProps = ({ auth }) => ({
  auth: auth,
});

const mapDispatchToProps = (dispatch) => ({
  __getDataStrapi: () => [dispatch(__getDataStrapi())],
});

export default connect(mapStateToProps, mapDispatchToProps)(HocAuthenticate(Index));
