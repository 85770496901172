import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { Page404, PageLogout } from '../pages';
import PageResetPassword from '../pages/v2/resetPassword';

import LayPortal from '../pages/portal';
// import MatomoProvider from './MatomoProvider';
export class Portal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttons: [],
      background: {
        url: 'https://giftano.imgix.net/assets/giftano-account.90d01e95e357473f85ab.png?auto=format',
      },
    };
  }

  componentDidMount() {
    const { modulContentStrapi } = this.props;
    const { code } = modulContentStrapi;
    if (code === 200) {
      this.setContent();
    }
  }

  setContent = () => {
    const { modulContentStrapi } = this.props;
    const { restData } = modulContentStrapi;
    const { portal_buttons, Background } = restData;
    this.setState((state) => ({
      ...state,
      buttons: portal_buttons ? portal_buttons : [],
      background: Background
        ? Background
        : {
            url: 'https://giftano.imgix.net/assets/giftano-account.90d01e95e357473f85ab.png?auto=format',
          },
    }));
  };
  render() {
    const { auth, loading, redirect, redirectTo, errorMessage } = this.props;
    const { background } = this.state;
    // console.log(redirect);

    if (!loading && auth && redirect && redirectTo !== '') {
      if (errorMessage === '') {
        // console.log(redirectTo);
        return (window.location = redirectTo);
        // console.log(redirectTo, loading, redirect);
      }
    }

    return (
      <Container fluid className='layoutWrapper default-page'>
        {/* <MatomoProvider> */}
        <Row className='h-100'>
          <Col
            lg='12'
            md='12'
            className='bg-account'
            style={{ backgroundImage: `url(${background.url})` }}
          >
            <Col className='login-block'>
              <BrowserRouter basename='/'>
                <Switch>
                  <Redirect from='/sign-up' to='/' />
                  <Redirect from='/no-password/:key' to='/' />

                  <Route
                    path={'/'}
                    exact
                    name='Portal'
                    title='Portal'
                    render={() => <LayPortal {...this.props} />}
                  />
                  <Route
                    path={'/reset-password/:key'}
                    name='Login Page'
                    title='Signup Page'
                    render={(props) => (
                      <PageResetPassword showLogo={false} {...this.props} {...props} />
                    )}
                  />
                  <Route
                    path={'/logout'}
                    exact={true}
                    name='Logout'
                    title='Logout'
                    render={() => <PageLogout {...this.props} />}
                  />

                  <Route render={() => <Page404 {...this.props} />} />
                </Switch>
              </BrowserRouter>
            </Col>
          </Col>
        </Row>
        {/* </MatomoProvider> */}
      </Container>
    );
  }
}

const mapStateToProps = ({ modulContentStrapi }) => ({
  modulContentStrapi: modulContentStrapi,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Portal);
