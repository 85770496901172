import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { Button, Form, FormGroup, FormText } from 'reactstrap';
import { InputField } from '../../../components';
import { __postSignUpNewAccount } from '../../../modules/Signup';
import { Row } from 'reactstrap';

class FormCreatePassword extends Component {
  constructor(props) {
    super(props);
    this.fields = [createRef(), createRef()];
    this.state = {
      data: {
        password: '',
        repassword: '',
      },

      error: '',
    };
  }
  handleSubmit = (e) => {
    e.preventDefault();
    var valid = true;
    var isValid = true;
    let { data } = this.state;
    const { password, repassword } = data;
    const { casess, dataUser } = this.props;
    const { change_password, update_profile } = casess;
    // trim data
    data.password = data.password.trim();

    for (let index = 0; index < this.fields.length; index++) {
      valid = this.fields[index].current.validate();
      if (!valid) {
        isValid = false;
      }
    }

    if (isValid) {
      if (password.trim() !== repassword.trim() && repassword !== '') {
        isValid = false;
        this.setState(() => ({
          error: 'Your password and repassword not match',
        }));
      }
    }

    if (isValid) {
      //   funtion to send data
      // console.log(dataUser);
      // return this.props.callback({ password: repassword }, 2);

      if (change_password && update_profile) {
        return this.props.callback({ password: repassword }, 2);
      } else if (change_password && !update_profile) {
        let dataSent = {
          password: repassword,
          session_id: dataUser.session_id,
        };
        // console.log(dataUser);
        // console.log(dataSent);
        return this.props.__postSignUpNewAccount(dataSent);
      }
    }
  };

  handleChange = (name, value) => {
    const { data } = this.state;
    this.setState((state) => ({
      ...state,
      data: {
        ...data,
        [name]: value,
      },
    }));
  };
  render() {
    const { data, error } = this.state;
    const { repassword, password } = data;
    const { modulSignUp } = this.props;
    const { loadingSetupaccount, code } = modulSignUp;
    // console.log(dataUser);
    return (
      <div>
        {code === 200 ? (
          <>
            <div className='container text-center d-flex justify-content-center'>
              <Row>
                <div className='login-container p-3 '>
                  <h4 className=' mb-4 text-center w-100'>
                    Thank you for completing your account
                  </h4>
                  <a
                    className='btn btn-primary  w-100 btn-google m-0 btn-block btn-outline-primary text-secondary'
                    href={'https://giftano.com'}>
                    Go to Homepage
                  </a>
                </div>
              </Row>
            </div>
          </>
        ) : (
          <>
            <div className=' d-flex justify-content-center'>
              <h4 className='my-3 text-black'>Set your password</h4>
            </div>
            <div>
              <p className='notice text-black-50'>
                *Your password must be at least 8 characters long
              </p>
            </div>
            <Form onSubmit={this.handleSubmit}>
              <FormGroup>
                <InputField
                  type='password'
                  clasname='login-input'
                  placeholder='Enter your password'
                  name='password'
                  validation='required'
                  value={password}
                  message='Please enter your password'
                  ref={this.fields[0]}
                  onChange={this.handleChange}
                  label='Password'
                />
              </FormGroup>

              <FormGroup>
                <InputField
                  type='password'
                  clasname='login-input'
                  placeholder='Enter your password'
                  name='repassword'
                  validation='required'
                  value={repassword}
                  message='Please enter your repassword'
                  ref={this.fields[1]}
                  onChange={this.handleChange}
                  label='Confirm Password'
                />
              </FormGroup>
              <FormGroup>
                <FormText color='danger'>{error}</FormText>
              </FormGroup>
              <FormGroup className='text-right form-group'>
                <Button
                  type='submit'
                  color='primary'
                  className='w-100 mt-3 btn-login'>
                  {!loadingSetupaccount ? 'Create Password' : 'loading...'}
                </Button>
              </FormGroup>
            </Form>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ modulSignUp }) => ({
  modulSignUp,
});

const mapDispatchToProps = (dispatch) => ({
  __postSignUpNewAccount: (params) => [
    dispatch(__postSignUpNewAccount(params)),
  ],
});

export default connect(mapStateToProps, mapDispatchToProps)(FormCreatePassword);
