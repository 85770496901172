import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Card,
  CardBody,
  Form,
  Row,
  FormGroup,
  FormText,
  Label,
  Col,
} from 'reactstrap';
import { InputField } from '../../../components';
import { Link } from 'react-router-dom';
import {
  __postDataValidateKey,
  __postResetPassword,
} from '../../../modules/Reset';
import { FiLogOut } from 'react-icons/fi';
import { FaUser } from 'react-icons/fa';
// const locationWindow = window.location.origin;

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.fields = [createRef(), createRef()];
    this.state = {
      data: {
        action: 'reset',
        password: '',
        confirm_password: '',
      },
      error: '',
    };
  }

  componentDidMount() {
    const { match, modulUserInfo } = this.props;
    const { params } = match;
    const { key } = params;
    const { data } = this.state;
    // console.log(this.props);
    if (key !== undefined) {
      this.props.__postDataValidateKey({
        action: 'validatekey',
        key: key,
      });
      this.setState((state) => ({
        ...state,
        data: {
          ...data,
          key: key,
        },
      }));
    }

    if (modulUserInfo.code === 200) {
      this.setUserInfo();
    }
  }

  setUserInfo = () => {
    const { modulUserInfo } = this.props;
    const { restData } = modulUserInfo;
    const { data } = restData;
    this.setState((state) => ({
      ...state,
      data: data,
    }));
  };

  handleSubmit = (e) => {
    e.preventDefault();
    var valid = true;
    var isValid = true;
    let { data, error } = this.state;
    let { password, confirm_password } = data;
    //trim password
    // data.password = data.password.trim();
    // data.confirm_password = data.confirm_password.trim();

    for (let index = 0; index < this.fields.length; index++) {
      valid = this.fields[index].current.validate();
      if (!valid) {
        isValid = false;
      }
    }

    if (isValid) {
      if (password !== confirm_password) {
        return this.setState((state) => ({
          ...state,
          error: 'Password and repassword are not the same',
        }));
      }

      this.setState((state) => ({
        ...state,
        error: '',
      }));
    }
    if (isValid && error === '') {
      //   funtion to send data
      this.props.__postResetPassword(data);
    }
  };

  handleChange = (name, value) => {
    const { data } = this.state;
    this.setState((state) => ({
      ...state,
      data: {
        ...data,
        [name]: value,
      },
    }));
  };

  render() {
    // console.log('test');
    const { modulValidateKey, modulResetPassword, userInfo } = this.props;
    const { loadingPage, code } = modulValidateKey;
    const { error, data } = this.state;
    const { password, confirm_password } = data;

    return (
      <div className='wrapper-login reset-password-page'>
        <div className='login-container'>
          <Card className='py-2 px-2'>
            <CardBody className='portal-container'>
              <Row className=''>
                <Col
                  md={4}
                  className='profile-scaffold d-none'>
                  <div className='profile-scaffold__img'>
                    <FaUser
                      size='50px'
                      color='var(--gf-primary-300)'
                    />
                  </div>
                  <h3 className='text-primary profile-name'>
                    Hi
                    {userInfo.full_name !== '' || userInfo.full_name
                      ? ' ' + userInfo.full_name + '!'
                      : ' ' + userInfo.email + '!'}
                  </h3>
                  <Link
                    to={'/logout'}
                    className='btn btn-link text-warning  py-2 d-none d-md-block d-lg-block'
                    style={{ textDecoration: 'none' }}>
                    <FiLogOut className='me-2' />
                    LOG OUT
                  </Link>
                  <img
                    className='d-none d-md-block d-lg-block'
                    src='https://giftano.imgix.net/assets/giftano-logo-white-lg.png?auto=format'
                    alt='giftano-logo-white'
                  />
                  <img
                    className='d-block d-md-none'
                    src='https://giftano.imgix.net/assets/giftano-white-square.png?auto=format'
                    alt='giftano-logo-white'
                  />
                </Col>
                <Col
                  md={12}
                  className='profile-form'>
                  <Row className='mb-3 '>
                    <a
                      className='w-100 '
                      href={`${window.location.origin}`}>
                      <img
                        src='https://giftano.imgix.net/assets/Giftano-Logo-with-text.png?auto=format'
                        className='auth-logo mx-auto d-block'
                        alt={'Giftano logo'}
                      />
                    </a>
                  </Row>
                  {!loadingPage ? (
                    <>
                      {code === 200 && modulResetPassword.code !== 200 ? (
                        <div className=''>
                          <h4 className=' mb-4 mt-4 text-center'>
                            Reset your Password
                          </h4>
                          <Form onSubmit={this.handleSubmit}>
                            <FormGroup>
                              <InputField
                                type='password'
                                label='Create your new password'
                                placeholder='Your password'
                                ref={this.fields[0]}
                                name='password'
                                onChange={this.handleChange}
                                validation={'required|match:password|min:8'}
                                disabled={modulResetPassword.loadingPage}
                                value={password}
                              />
                            </FormGroup>

                            <FormGroup>
                              <InputField
                                type='password'
                                label='Repeat your password'
                                placeholder='Your confirm password'
                                ref={this.fields[1]}
                                name='confirm_password'
                                onChange={this.handleChange}
                                validation={'required|match:password|min:8'}
                                disabled={modulResetPassword.loadingPage}
                                value={confirm_password}
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label>
                                Your password needs to be at least 8 characters
                                long
                              </Label>
                            </FormGroup>
                            <FormGroup>
                              {error !== '' ? (
                                <FormText color='danger'>{error}</FormText>
                              ) : (
                                ''
                              )}
                            </FormGroup>
                            <FormGroup className='text-center'>
                              <Button
                                type='submit'
                                color='primary'
                                className='w-75 my-3 m-auto btn-login'
                                disabled={modulResetPassword.loadingPage}>
                                {!modulResetPassword.loadingPage
                                  ? 'Save'
                                  : 'Loading...'}
                              </Button>
                            </FormGroup>
                          </Form>
                        </div>
                      ) : (
                        <>
                          {code === 400 ? (
                            <div className='login-container  p-3 '>
                              <h4 className='mt-4 mb-4 text-center'>
                                Expired token. Please request a new password
                                reset link.
                              </h4>

                              <a
                                className='btn btn-primary  w-100 btn-google m-0 btn-block btn-outline-primary text-secondary'
                                href='https://giftano.com/'>
                                Go to Homepage
                              </a>
                            </div>
                          ) : (
                            ''
                          )}
                          {modulResetPassword.code === 200 ? (
                            <>
                              <div className='container text-center d-flex justify-content-center'>
                                <Row>
                                  <div className='login-container p-3 '>
                                    <h4 className=' mb-4 text-center w-100'>
                                      Your Password has been successfully
                                      updated.
                                    </h4>
                                    <a
                                      className='btn btn-primary  w-100 btn-google m-0 btn-block btn-outline-primary text-secondary'
                                      href={window.location.origin}>
                                      Go to Login Page
                                    </a>
                                  </div>
                                </Row>
                              </div>
                            </>
                          ) : (
                            ''
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <h6 className='text-center'>Loading...</h6>
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  modulValidateKey,
  modulResetPassword,
  modulUserInfo,
}) => ({
  modulValidateKey,
  modulResetPassword,
  modulUserInfo: modulUserInfo,
});

const mapDispatchToProps = (dispatch) => ({
  __postDataValidateKey: (params) => [dispatch(__postDataValidateKey(params))],
  __postResetPassword: (params) => [dispatch(__postResetPassword(params))],
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
